import React, {Component} from 'react';
import CanvasJSReact from '../canvasjs.react';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class BarChart extends Component {

    constructor(props) {
        super(props);
        this.state = {data: null};
        this.demo_data = [
            {label: "Apple", y: 20},
            {label: "Orange", y: 40},
            {label: "Banana", y: 20},
            {label: "Mango", y: 60},
            {label: "Grape", y: 30}
        ];
    }

    componentDidMount() {
        this.setState({
            data: [{
                type: "column",
                dataPoints: this.props.dataPoints|| this.demo_data
            }]
        });
    }

    render() {
        const options = {
            title: {
                text: "Traffic Speed",
                fontSize: 18,
            },
            data: this.state.data
        }
        return (
            <div>
                <CanvasJSChart options={options}
                    /* onRef = {ref => this.chart = ref} */
                />
            </div>
        );
    }
}

export default BarChart;
