import './App.css';
import BarChart from './components/BarChart';
import {Component} from "react";
import axios from 'axios';
import Pie from "./components/PieChart";
import {Grid, Row, Col} from 'react-flexbox-grid';
import TableView from "./components/TableView";

//const port = 8081; // 8080 | 44314 or others as provided by backend server
//const host = "localhost";
const host = "http://myjava-env-1.eba-gbdvqenn.ap-east-1.elasticbeanstalk.com"; //"localhost"
class App extends Component {
    constructor(props) {
        super(props);

        // this.speedApiUrl = `http://localhost:${port}/api/v1/SpeedInfo`;
        // this.satuationApiUrl = `http://localhost:${port}/api/v1/SaturationInfo`;
        // this.tableDataApiUrl = `http://localhost:${port}/api/v1/TrafficData`;

        this.speedApiUrl = `${host}/api/v1/SpeedInfo`;
        this.satuationApiUrl = `${host}/api/v1/SaturationInfo`;
        this.tableDataApiUrl = `${host}/api/v1/TrafficData`;

        this.state = {
            barDataPoints: null,
            satuationDataPoints: null,
            tableData: null,
            isLoadingPieChartData: true,
            isLoadingBarChartData: true,
            isLoadingTableData: true,
        };
        this.fetchDataAsync = this.fetchDataAsync.bind(this);
    }



    async fetchDataAsync() {
        this.setState({
            ...this.state,
            isLoadingPieChartData: true,
            isLoadingBarChartData: true,
            isLoadingTableData: true
        });



        try {
            const satuationresponse = await axios.get(this.satuationApiUrl);
            this.setState({
                ...this.state,
                satuationDataPoints: satuationresponse.data,
                isLoadingPieChartData: false
            });

            const speed_response = await axios.get(this.speedApiUrl);
            this.setState({
                ...this.state,
                barDataPoints: speed_response.data,
                isLoadingBarChartData: false
            });


            const tableResponse = await axios.get(this.tableDataApiUrl);
            this.setState({
                ...this.state,
                tableData: tableResponse.data,
                isLoadingTableData: false
            });
        } catch (e) {
            console.log(e);
            this.setState({
                ...this.state,
                isLoadingPieChartData: false,
                isLoadingBarChartData: false,
                isLoadingTableData: false
            });
        }
    };

    async componentDidMount() {
        await this.fetchDataAsync();
    }


    render() {
        const {barDataPoints, satuationDataPoints, tableData} = this.state;


        return (
            <div className="App">
                {this.renderHeaders()}
                {this.renderChartAndTables(satuationDataPoints, barDataPoints, tableData)}
            </div>
        );
    }

    renderLoadingIndicator = () => {
        return <p className="App"> Loading ...</p>;
    }

    renderHeaders() {
        return (
            <div>
                <h1 className="Left"> Traffic Conditions Analysis</h1>
                <h6 className="Left"> Updated Since {new Date().toLocaleString()}</h6>
                {this.RenderRefreshButton()}
                <h2 className="Left"> Traffic Overview</h2>
            </div>);
    }

    RenderRefreshButton() {
        return (
            <Row>
                <Col xsOffset={11} xs={1}>
                    <button onClick={this.fetchDataAsync}>Refresh</button>
                </Col>
            </Row>);
    }

    renderChartAndTables(satuationDataPoints, barDataPoints, tableData) {
        const {isLoadingPieChartData, isLoadingBarChartData, isLoadingTableData} = this.state;
        return (
            <Grid fluid style={{"margin-bottom": "100px"}}>
                <Row>
                    {this.RenderPieChart(satuationDataPoints, isLoadingPieChartData)}
                    {this.RenderBarChart(barDataPoints, isLoadingBarChartData)}
                </Row>

                <Row style={{height: "20px"}}/>
                <h2 className="Left">Road Speed Information</h2>

                {this.renderTableView(tableData, isLoadingTableData)}

            </Grid>);
    }

    renderTableView(tableData, isLoading) {
        if (isLoading)
            return this.renderLoadingIndicator();
        return <TableView data={tableData}/>;
    }

    RenderBarChart(barDataPoints, isLoading) {
        if (isLoading)
            return this.renderLoadingIndicator();
        return <Col xs={6} md={6}>
            <BarChart dataPoints={barDataPoints}/>
        </Col>;
    }

    RenderPieChart(satuationDataPoints, isLoading) {
        if (isLoading)
            return this.renderLoadingIndicator();
        return <Col xs={6} md={6}>
            <Pie dataPoints={satuationDataPoints}/>
        </Col>;
    }
}

export default App;
