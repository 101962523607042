import React, {Component} from 'react';
import DataTable from 'react-data-table-component';

class TableView extends Component {

    constructor(props) {
        super(props);
        this.state = {data: null};
        this.columns = [
            {
                name: 'Index',
                sortable: false,
                selector: 'INDEX'
            },
            {
                name: 'Road Id',
                selector: 'LINK_ID',
                sortable: true,
                right: false,
                compact: true
            },
            {
                name: 'Region',
                selector: 'REGION',
                sortable: true,
                right: false,
                compact: true
            },
            {
                name: 'Road Type',
                selector: 'ROAD_TYPE',
                sortable: false,
                right: false,
                compact: true
            },
            {
                name: 'Satuation Level',
                selector: 'ROAD_SATURATION_LEVEL',
                sortable: true,
                right: false,
                compact: true
            },
            {
                name: 'Traffic Speed',
                selector: 'TRAFFIC_SPEED',
                sortable: true,
                right: false,
                compact: true
            },
            {
                name: 'Capture Date',
                selector: 'CAPTURE_DATE',
                sortable: true,
                right: true
            }
        ];
    }

    componentDidMount() {
        this.setState({
            data: [{
                type: "pie",
                startAngle: -90,
                dataPoints: this.props.dataPoints
            }]
        });
    }

    render() {
        const data = this.props.data || this.mockData;
        return (
            <DataTable
                columns={this.columns}
                striped="true"
                theme="solarized"
                data={data}
                paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500]}
                pagination={true}
                paginationComponentOptions={{
                    rowsPerPageText: 'Index:',
                    rangeSeparatorText: 'of',
                    noRowsPerPage: false,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: 'All'
                }}
            />
        );
    }

}

export default TableView;
